/* custom.scss */

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";



$custom-theme-colors: (
  "dark-1":#1b1d22,
  "dark-2":#22252a,
  "dark-3":#292c33,
  "dark-4":#3c404b,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text: map-merge($utilities-text, $utilities-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");
$utilities-bg: map-merge($utilities-bg, $utilities-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");


@import "../../node_modules/bootstrap/scss/bootstrap";
