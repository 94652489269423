@import url('https://fonts.googleapis.com/css2?family=Inter');
@import url('https://fonts.googleapis.com/css2?family=Merriweather');
@import url('https://fonts.googleapis.com/css2?family=Poppins');



.site-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.store-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.store-title2 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #333333;
}

.store-title2-bold {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #333333;
}



.store-subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
/*    font-weight: bold;*/
    color: black;
}

.store-subtitle-bold {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.store-body {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 14px;
}

.store-body-white {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 14px;
}

.store-body-bold {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight:bold;
    font-size: 14px;
}

.store-fineprint {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: 12px;
}


/*.store-body {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight:bold;
    font-size: 14px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.store-title {
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: bold;
    color: black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.store-subtitle {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg-light-gray {
  background-color: #f8f8f8;
}
.bg-blue {
  background-color: #305f97;
}

.bg-tan {
  background-color: #f9f5f2;
}

.bg-blue2 {
  background-color: #25487a;
}

.bg-black {
  background-color: #0e0e10;
}

.bg-black2 {
  background-color: #18181b;
}

.store-blue-btn2 {
 background-color: #39A0ED!important;
 font-weight: bold;
 color:white!important;
}

.btn-light-gray {
  background-color: #d3d3d3!important;
  color: black!important;
  font-weight:bold!important;
}


.store-blue-btn {
 background-color: #3493d6!important;
 font-weight: bold;
 color:white!important;
}

.btn-purple {
  background-color: #9146FF!important;
  color:white!important;
  font-weight:bold!important;
}

.btn-green {
  background-color: #57A773!important;
  color:white!important;
  font-weight:bold!important;
}

.btn-primary{
  color:white!important;
  font-weight:bold!important;
}

.dark-link {
  color: black!important;
}

.purple-link {
  color: #9146FF!important;
}

.green-link {
  color: #57A773!important;
}

.App-link {
  color: #61dafb;
}

.vh-10 {
  height: 10vh;
}
.vh-20 {
  height: 20vh;
}
.vh-30 {
  height: 30vh;
}
.vh-40 {
  height: 40vh;
}

.vh-45 {
  height: 45vh;
}

.vh-50 {
  height: 50vh;
}
.vh-60 {
  height: 60vh;
}
.vh-70 {
  height: 70vh;
}
.vh-75 {
  height: 75vh;
}
.vh-80 {
  height: 80vh;
}
.vh-90 {
  height: 90vh;
}

.min-vh-50 {
  height: 50vh;
}
.min-vh-60 {
  height: 60vh;
}
.min-vh-70 {
  height: 70vh;
}
.min-vh-80 {
  height: 80vh;
}
.min-vh-90 {
  height: 90vh;
}
